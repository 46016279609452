import logo from "../assets/sos.png";
import React, {useState} from "react";
import CorrespondentBlock from "./CorrespondentBlock";
import {toast} from "react-toastify";
import {isValidPhoneNumber} from "libphonenumber-js";
import {Contact} from "../models/Contact";
import {Link, useNavigate} from "react-router-dom";

export default function CreateCorrespondents({setSteps, first = true}: { setSteps: any, first: boolean }) {
    const getContactsFromLocalStorage = (): Contact[] | null => {
        const savedContacts = localStorage.getItem('correspondents');
        if (savedContacts) {
            return JSON.parse(savedContacts) as Contact[];
        }
        return null;
    };
    const [contacts, setContacts] = useState<Contact[]>(() => {
        return getContactsFromLocalStorage() || [
            {
                name: '',
                number: '',
            },
        ]
    });
    const navigate = useNavigate();

    const validateCorrespondents = () => {
        let save = true;
        contacts.forEach((value: any, index) => {
            if (value.name === '') {
                toast.error(`Le nom du contact Nº${index + 1} est vide`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                save = false;
                return;
            }

            if (!isValidPhoneNumber(value.number as string)) {
                toast.error(`Le numéro du contact Nº${index + 1} est invalide`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                save = false
                return;
            }
        });

        if (save) {
            localStorage.setItem('correspondents', JSON.stringify(contacts));
            const user = JSON.parse(localStorage.getItem('user') || '[]');
            if (Object.keys(user).length > 0) {
                contacts.forEach((v: any) => {
                    const request = {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({
                            content: `${user.name} (${user.number}) vous a désigné comme contact d'urgence avec l'application Urgence SMS.`,
                            to: v.number
                        })
                    };
                    fetch('https://active-business.eu/api/v2/pwa/send-alarm-all', request)
                })
            }

            if (first) {
                setSteps(4);
            } else {
                navigate('/');
            }
        }
    }

    const addContact = () => {
        let tmp = [...contacts];
        tmp.push({
            name: '',
            number: ''
        });
        setContacts(tmp);
    }

    return (
        <div className={`container ${first ? 'p-4' : ''}`}>
            {!first &&
                <nav className="navbar bg-body-tertiary">
                    <div className="container-fluid">
                        <Link to='/' className="navbar-brand mb-0 h1">Retour</Link>
                    </div>
                </nav>}
            <div className='text-center'>
                <img
                    src={logo}
                    className="img-fluid mt-3 mb-3"
                    style={{
                        width: '35%'
                    }} alt=''/>
            </div>
            <div className='text-center mb-3'>
                <h3>
                    Désignez vos contacts d'urgence<br/>
                    Un SMS d'informations leur sera envoyé
                </h3>
            </div>
            {contacts.map((v: object, i: number) => (
                <CorrespondentBlock number={i + 1} index={i} setContacts={setContacts} contacts={contacts} key={i}/>
            ))}
            <div className=''>
                {contacts.length < 3 &&
                    <div className='d-flex justify-content-center mt-5'>
                        <button className='btn btn-primary' onClick={addContact}>
                            Ajouter un correspondant
                        </button>
                    </div>}
            </div>
            <div className='text-center mt-3'>
                <button className='btn btn-outline-dark'
                        onClick={validateCorrespondents}
                >
                    Valider
                </button>
            </div>
        </div>
    )
}