import React from 'react';
import './App.css';

import {HashRouter, Route, Routes} from "react-router-dom";
import Main from "./pages/Main";
import Correspondents from "./pages/Correspondents";
import {ToastContainer} from "react-toastify";
import Install from "./pages/Install";
import About from "./pages/About";
import CreateCorrespondents from "./components/CreateCorrespondents";
import Home from "./pages/Home";

const App = () => {
    return (
        <HashRouter>
            <ToastContainer/>
            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/correspondents' element={<CreateCorrespondents setSteps={null} first={false}/>}/>
                <Route path='/install' element={<Install/>}/>
                <Route path='/about' element={<About/>}/>
            </Routes>
        </HashRouter>
    )
}

export default App;