import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

export default function About() {
    const navigate = useNavigate();
    const [user] = useState(JSON.parse(localStorage.getItem('user') || '[]'))

    useEffect(() => {
        const first = localStorage.getItem('first_launch');
        if (first === null) {
            navigate('/install');
            return;
        }

        if(Object.keys(user).length === 0){
            navigate('/install');
            return;
        }
    }, [])

    return (
        <div className='container'>
            <nav className="navbar bg-body-tertiary">
                <div className='navbar-nav'>
                    <Link to='/' className="navbar-brand mb-0 h1 d-none">FERMER</Link>
                </div>
                <form className="d-flex" role="search">
                    <div className='navbar-nav'>
                        <Link to='/' className="navbar-brand mb-0 h1">FERMER</Link>
                    </div>
                </form>
            </nav>
            <div className='text-center mt-5'>
                <h4>À propos</h4>
            </div>
            <div className='p-3'>
                <p>
                    Message SOS © {new Date().getFullYear()}
                </p>
                <p>Tous droits résérvés.</p>
                <p>
                    Licence accordée à :<br/>
                    {user.name}<br/>
                    au N°: {user.number}
                </p>
                <p>
                    Avertissement :
                </p>
                <p style={{
                    textAlign: 'justify'
                }}>
                    Cette application est protégée par la loi relative aux droits d'auteur, aux droits de protection
                    de la propriété intellectuelle et les conventions internationales y afférents. Toute
                    reproduction ou Distribution partielle du logiciel par quelque moyen que ce soit, est
                    strictement interdite.
                </p>
                <p style={{
                    textAlign: 'justify'
                }}>
                    Toute personne ne respectant pas ces dispositions se rendra coupable du délit de contrefaçon et
                    ce sera possible des sanctions pénales prévues par la loi.
                </p>
            </div>

        </div>
    )
}