import React, {useState} from "react";
import logo from "../assets/logo.png";
import school1 from "../assets/school1.jpg"
import hands from "../assets/hands-joined-by-team.jpg"
import screenshot from "../assets/screenshot.png"

export default function Home() {
    const [choice, setChoice] = useState('0');

    const handleClick = () => {

    }

    return (
        <div className='container'>
            <div className='col-md-3 col-12 ms-auto me-auto'>
                <div className='text-center'>
                    <img
                        src={logo}
                        className="img-fluid mt-5"
                        style={{
                            width: '50%'
                        }} alt=''/>
                </div>
                <div style={{
                    width: '75px',
                    border: '1px solid red'
                }} className='ms-auto me-auto mt-5 mb-5'></div>
                <div className='text-center'>
                    <h4 className='text-danger fw-bold'>
                        En cas d'urgence, détresse ou agression, chaque seconde compte.
                    </h4>
                    <p className='mt-5'>
                        C'est pourquoi Urgent Assistance permet d'envoyer rapidement un SMS de demande de secours à tes
                        contacts d'urgence.
                    </p>
                    <div className='row'>
                        <img src={hands} className='img-fluid' alt='shool1'/>
                    </div>
                    <div style={{
                        width: '75px',
                        border: '1px solid red'
                    }} className='ms-auto me-auto mt-5 mb-5'></div>
                    <h4 className='fw-bold text-blue'>
                        Pour protéger les enfants
                    </h4>
                    <p className='text-blue'>
                        Retour de l’école, harcèlement, pour sécuriser la baby-sitter
                    </p>
                    <div style={{
                        width: '75px',
                        border: '1px solid red'
                    }} className='ms-auto me-auto mt-5 mb-5'></div>
                    <h4 className='fw-bold text-blue'>
                        Pour protéger les plus grands
                    </h4>
                    <p>
                        Malaise, convalescence, insécurité, sérénité
                    </p>
                    <div style={{
                        width: '75px',
                        border: '1px solid red'
                    }} className='ms-auto me-auto mt-5 mb-5'></div>
                    <p>
                        Urgent Assistance offre la possibilité de préenregistrer jusqu’à 3 contacts de confiance, ceux
                        vers qui tu souhaites envoyer une alerte en cas de besoin.
                    </p>
                    <p>
                        En 2 clics, tu peux leur envoyer une demande de secours indiquant ta position géographique, leur
                        permettant ainsi de venir rapidement à ton aide.
                    </p>
                    <div className='mt-5'>
                        <span>avec</span>
                        <h3 className='fw-bold'><span className='text-danger'>URGENT</span> ASSISTANCE</h3>
                        <p>
                            Que tu sois chez toi ou à l’extérieur, Il suffit d’un double-clic sur le logo pour envoyer
                            un SMS
                            avec ta géolocalisation GPS
                            vers tes contacts d’urgence. (de 1 à 3)
                        </p>
                        <img src={screenshot} className='img-fluid' alt='shool1'/>
                        <p>
                            Compatible Android et iOS
                        </p>
                        <div style={{
                            width: '75px',
                            border: '1px solid red'
                        }} className='ms-auto me-auto mb-5'></div>
                        <p>
                            Alors, n’hésites pas. Choisis la sécurité
                            et la tranquillité d'esprit.
                        </p>
                        <div style={{
                            width: '75px',
                            border: '1px solid red'
                        }} className='ms-auto me-auto mt-5 mb-5'></div>
                        <h3>Forfait UA</h3>
                        <div className='d-flex justify-content-center align-items-end'>
                            <h2 className='mb-0 text-danger'>10 alarmes</h2><span className='text-muted'>/ mois</span>
                        </div>
                        <div style={{
                            width: '75px',
                            border: '1px solid grey'
                        }} className='ms-auto me-auto mt-2 mb-3'></div>
                        <div className='d-flex justify-content-center align-items-end mb-5'>
                            <h1 className='mb-0 text-danger'>
                                4<sup>€,99</sup>
                            </h1>
                            <span className='text-muted'>/ mois</span>
                        </div>
                        <b>Sans engagement</b>
                        <div>
                            <input type='radio' name='choice' value={'1'} checked={choice === '1'}
                                   onChange={event => setChoice(event.target.value)}/>
                        </div>
                        <div style={{
                            width: '75px',
                            border: '1px solid red'
                        }} className='ms-auto me-auto mt-2 mb-2'></div>
                        <h2>
                            Besoin d’alarmes en plus choisis le pack
                        </h2>
                        <div className='d-flex align-items-center justify-content-center mb-3'>
                            <div className='col-md'>
                                <div className='bg-light-blue rounded p-3 me-1'>
                                    <h4>Pack<br/>5 alarmes</h4>
                                    <h3 className='text-danger'>
                                        3,99 €
                                    </h3>
                                    <div style={{
                                        width: '75px',
                                        border: '1px solid grey'
                                    }} className='ms-auto me-auto'></div>
                                    <p>
                                        Gardé d’un mois sur l’autre
                                    </p>
                                </div>
                                <div>
                                    <input type='radio' name='choice' value={'2'} checked={choice === '2'}
                                           onChange={event => setChoice(event.target.value)}/>
                                </div>
                            </div>
                            <div className='col-md'>
                                <div className='bg-light-blue rounded p-3 ms-1'>
                                    <h4>Pack<br/>10 alarmes</h4>
                                    <h3 className='text-danger'>
                                        6,99 €
                                    </h3>
                                    <div style={{
                                        width: '75px',
                                        border: '1px solid grey'
                                    }} className='ms-auto me-auto'></div>
                                    <p>
                                        Gardé d’un mois sur l’autre
                                    </p>
                                </div>
                                <div>
                                    <input type='radio' name='choice' value={'3'} checked={choice === '3'}
                                           onChange={event => setChoice(event.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <button
                                onClick={handleClick}
                                className='btn btn-danger btn-block'
                                disabled={choice === '0'}>
                                Souscrire
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}