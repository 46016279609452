import React, {useState} from "react";
import logo from "../assets/sos.png";
import PhoneInput from "react-phone-number-input";
import {E164Number, isValidPhoneNumber} from "libphonenumber-js";

export default function AccountCreation({setSteps} : {setSteps: any}){

    const [value, setValue] = useState<undefined|E164Number>();
    const [name, setName] = useState('');

    const nextStep = () => {
        if(name !== ''){
            if(isValidPhoneNumber(value?.toString() as string)){
                localStorage.setItem('user', JSON.stringify({
                    name: name,
                    number:value
                }))
                setSteps(3)
            }
        }
    }

    return (
        <div className='container p-4'>
            <div className='text-center'>
                <img
                    src={logo}
                    className="img-fluid mt-3 mb-3"
                    style={{
                        width: '35%'
                    }} alt=''/>
            </div>
            <div className='text-center'>
                <h3>Créez votre compte</h3>
                <div className='mt-5'>
                    <input placeholder='Nom et prénom(s)'
                           type='text'
                           value={name}
                           onChange={event => setName(event.target.value)}
                           className='form-control mb-3'/>
                    <PhoneInput
                        international={false}
                        defaultCountry="FR"
                        countries={["FR"]}
                        country="FR"
                        className='form-control'
                        placeholder='Numero de téléphone'
                        onChange={setValue}/>
                    <div className='text-center mt-3'>
                        <button className='btn btn-outline-dark'
                                onClick={nextStep}>
                            Valider
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}