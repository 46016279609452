import React, {useEffect, useState} from "react";
import logo from "../assets/sos.png";
import {useAddToHomescreenPrompt} from "../hooks/InstallPrompt";
import {useNavigate} from "react-router-dom";
import AccountCreation from "../components/AccountCreation";
import CreateCorrespondents from "../components/CreateCorrespondents";

export default function Install() {
    const [steps, setSteps] = useState(1);
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    }
    const [prompt, promptToInstall] = useAddToHomescreenPrompt();
    const navigate = useNavigate();
    const installAndContinue = (e: React.MouseEvent) => {
        localStorage.setItem('first_launch', 'no');
        if (!isInStandaloneMode() && !isIos()) {
            promptToInstall();
        }

        navigate('/');
    }

    useEffect(() => {
        const first = localStorage.getItem('first_launch');
        if (first !== null) {
            navigate('/');
        }
    }, [])

    return (
        <>
            {steps === 1 &&
                <div className='container p-4'>
                    <div className='text-center'>
                        <img
                            src={logo}
                            className="img-fluid mt-3 mb-3"
                            style={{
                                width: '35%'
                            }} alt=''/>
                    </div>
                    <div>
                        <h2>1. Description</h2>
                        <p>
                            Urgence SMS est une webapp conçue pour envoyer des demandes de secours par SMS.
                        </p>
                    </div>
                    <div className='mt-3'>
                        <h2>2. Fonctionnement</h2>
                        <p>
                            Un simple double-clic sur le logo Urgence
                            SMS permet d'envoyer un SMS indiquant votre géolocalisation GPS vers vos contacts.
                        </p>
                    </div>
                    <div className='mt-3'>
                        <h2>3. Géolocalisation GPS</h2>
                        <p>
                            La qualité de la géolocalisation dépend du capteur GPS de votre téléphone.<br/>
                            Active Assistance décline toute responsabilité en cas de géolocalisation erronée ou
                            appromixative.
                        </p>
                    </div>
                    <div className='mt-3'>
                        <h2>4. Recommandation</h2>
                        <p>
                            Il est recommandé de placer l'icône Urgence SMS sur l'écran d'accueil ou sur l'un des
                            emplacements prioritaires de votre smartphone.
                        </p>
                    </div>
                    <div className='text-center mt-3'>
                        <button className='btn btn-outline-dark'
                                onClick={event => setSteps(2)}>
                            Valider et installer
                        </button>
                    </div>

                </div>}
            {steps === 2 && <AccountCreation setSteps={setSteps}/>}
            {steps === 3 && <CreateCorrespondents setSteps={setSteps} first={true}/>}
            {steps === 4 &&
                <div>
                    <div className='container p-4'>
                        <div className='text-center'>
                            <img
                                src={logo}
                                className="img-fluid mt-3 mb-3"
                                style={{
                                    width: '35%'
                                }} alt=''/>
                        </div>
                        <div className='text-center'>
                            <h3>Installer sur l'ecran d'accueil</h3>
                        </div>
                        {!isIos() &&
                            <div className='text-center mt-3'>
                                <button className='btn btn-outline-dark'
                                        onClick={e => installAndContinue(e)}>
                                    Valider et installer
                                </button>
                            </div>}

                        {isIos() &&
                            <div className='text-center mt-3'>
                                <p>
                                    1. Appuyez sur <i className="fa-solid fa-arrow-up-from-bracket"></i><br/>
                                    2. Puis rajouter sur l'ecran d'accueil
                                </p>
                                <button className='btn btn-outline-dark'
                                        onClick={e => installAndContinue(e)}
                                >Continuer
                                </button>
                            </div>}
                    </div>

                </div>}
        </>
    )
}