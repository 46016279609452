import PhoneInput from "react-phone-number-input";
import React from "react";
import {E164Number} from "libphonenumber-js";

export default function CorrespondentBlock({number, index, setContacts, contacts}: {
    number: number,
    index: number,
    setContacts: any,
    contacts: any
}) {

    const setNumber = (value: any) => {
        let tmp = [...contacts];
        tmp[index].number = value;
        setContacts(tmp);
    }

    const removeBlock = () => {
        if(contacts.length === 1){
            return;
        }

        let tmp = [...contacts];
        tmp.splice(index, 1);
        setContacts(tmp);
    }

    return (
        <div className='position-relative mb-5'>
            <div className='position-absolute top-0 end-0 pe-2'>
                <i
                    className="fa-solid fa-circle-xmark text-danger fs-2 pointer-event"
                    onClick={removeBlock}
                ></i>
            </div>
            <h5>Contact Nº{number}</h5>
            <input placeholder='Nom et prénom(s)'
                   type='text'
                   className='form-control mb-3'
                   value={contacts[index].name}
                   onChange={(event => {
                       let tmp = [...contacts];
                       tmp[index].name = event.target.value;
                       setContacts(tmp);
                   })}
            />
            <PhoneInput
                international={false}
                defaultCountry="FR"
                countries={["FR"]}
                country="FR"
                className='form-control'
                placeholder='Numero de téléphone'
                value={contacts[index].number}
                onChange={value => setNumber(value)}
            />
        </div>
    )
}